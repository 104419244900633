import React, { useEffect, useState, useContext } from 'react';
import { getAuth, onAuthStateChanged, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'; // Note updatePassword imported
import { getFirestore, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import styled from 'styled-components';
import contentPT from '../content/secureprofilepage/contentPT';
import contentBR from '../content/secureprofilepage/contentBR';
import LanguageContext from '../context/LanguageContext';

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column; /* Change to column to stack the title and sections */
  padding: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
  max-width: 1400px;
  margin: 0 auto;
`;

const LeftSection = styled.div`
  flex: 6; /* Takes 60% of the space */
  padding-right: 10rem;
`;

const RightSection = styled.div`
  flex: 4; /* Takes 40% of the space */
`;

const PasswordInput = styled.input`
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1rem;
`;

const CancelButton = styled.button`
  background-color: #FF5733;
  color: #fff;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;

  &:hover {
    background-color: #F39C12;
  }
`;

const Title = styled.h1`
  width: 100%; /* Ensure title takes 100% of the width */
  font-family: 'Poppins', sans-serif;
  font-size: 4vw;
  color: #2980B9;
  text-align: center;
  margin-bottom: 3vw;

  @media (max-width: 768px) {
    font-size: 5vw;
  }
`;

const Subtitle = styled.h2`
  font-family: 'Nunito', sans-serif;
  font-size: 1.5rem;
  color: #F39C12;
  text-align: center;
  margin-bottom: 2rem;

    @media (max-width: 768px) {
    font-size: 3.5vw;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ToggleSection = styled.div`
  margin-bottom: 0.1rem;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #2980b9;
  display: flex;
  align-items: center;
`;

const ToggleArrow = styled.span`
  transition: transform 0.3s ease;
  margin-left: 1.2rem;
  ${props => props.isOpen && `
    transform: rotate(180deg);
  `}
`;

const SectionContent = styled.div`
  display: ${props => (props.isVisible ? 'grid' : 'none')};
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Button = styled.button`
  background-color: #FF5733;
  color: #fff;
  border: none;
  padding: 0.6rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.5vw;
  font-family: 'Nunito', sans-serif;
  letter-spacing: 0.05rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #F39C12;
  }
    @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const Select = styled.select`
  font-size: 1vw;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 2.6vw;
  }
`;

const StyledLink = styled.a`
  font-family: 'Nunito', sans-serif;
  font-size: 1.6vW;
  color: #2980B9;
  text-decoration: none;
  margin-top: 1rem;
  display: inline-block;

  &:hover {
    text-decoration: underline;
    color: #F39C12;
  }

  @media (max-width: 768px) {
    font-size: 3vw;
    text-decoration: underline;
    font-weight: 600;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const ModalButton = styled.button`
  margin: 10px;
`;
  
const SecureProfilePage = () => {
  const {language} = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({
    name: '',
    airlinePreference: [],
    budgetRanges: [],
    homeAirport: [],
  });
  const [sectionsVisibility, setSectionsVisibility] = useState({
    homeAirport: false,
    budgetRanges: false,
    airlinePreference: false,
  });

  const Modal = ({ onClose, onSelectOption }) => (
    <ModalOverlay>
      <ModalContent>
        <h2>Cancelar Assinatura</h2>
        <ModalButton onClick={() => { onSelectOption('delete'); onClose(); }}>
          Cancelar e excluir todos os dados
        </ModalButton>
        <ModalButton onClick={() => { onSelectOption('downgrade'); onClose(); }}>
          Cancelar Premium e mudar para Gátis
        </ModalButton>
        <ModalButton onClick={onClose}>{content.button}</ModalButton>
      </ModalContent>
    </ModalOverlay>
  );

  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, 'subscribers', currentUser.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } else {
        setUser(null);
        setUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const [isUpdating] = useState(false);

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      alert("Utilizador não autenticado. Por favor, faça login novamente.");
      return;
    }
  
    try {
      const currentPassword = prompt("Por favor, insira sua senha atual:");
  
      if (!currentPassword) {
        alert("A atualização da senha foi cancelada.");
        return;
      }
  
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
  
      // Use updatePassword function from firebase/auth
      await updatePassword(user, newPassword);
  
      alert("Senha atualizada com sucesso!");
      setNewPassword(""); 
    } catch (error) {
      console.error("Erro ao atualizar a senha:", error);
      // Display a generic Portuguese error message instead of error.message
      alert("Falha ao atualizar a senha. Por favor, tente novamente.");
    }
  };    
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleCancelSubscription = async (option) => {
    const db = getFirestore();
    if (option === 'delete') {
      // Delete user from Firestore and Authentication
      await deleteDoc(doc(db, 'subscribers', user.uid));
      const auth = getAuth();
      await auth.currentUser.delete();
      alert(content.cancel.alert1);
    } else if (option === 'downgrade') {
      // Update user to free subscription
      await updateDoc(doc(db, 'subscribers', user.uid), {
        subscriptionType: 'Free',
        airlinePreference: [],
        budgetRanges: [],
        validUntil: null,
        subscriptionStatus: 'Inactive',
      });
      alert(content.cancel.alert2);
    }
  };

  const toggleSectionVisibility = (section) => {
    setSectionsVisibility((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: checked
        ? [...prev[name], value]
        : prev[name].filter(item => item !== value)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const db = getFirestore();
    await updateDoc(doc(db, 'subscribers', user.uid), userData);
    alert(content.submitalert);
  };

  if (!user || !userData) {
    return <div>A carregar...</div>;
  }

  return (
    <ProfileContainer>
      <Title>Área Pessoal - {userData.name || user.email}</Title>
      <div style={{ display: 'flex', width: '100%' }}>
        <LeftSection>
          <Subtitle>{content.subtitle}</Subtitle>
          <Form onSubmit={handleSubmit}>

        <ToggleSection onClick={() => toggleSectionVisibility('homeAirport')}>
          <span>{content.span}</span>
          <ToggleArrow isOpen={sectionsVisibility.homeAirport}>⬇️</ToggleArrow>
        </ToggleSection>
        <SectionContent isVisible={sectionsVisibility.homeAirport}>
 		      <Select
            id="homeAirport"
            name="homeAirport"
      value={userData.homeAirport[0] || ''}
            onChange={(e) => setUserData(prev => ({ ...prev, homeAirport: [e.target.value] }))}
          >
            <option value="LISBOA-PT">LISBOA-PT</option>
            <option value="PORTO-PT">PORTO-PT</option>
            <option value="RIO DE JANEIRO-BR">RIO DE JANEIRO-BR</option>
            <option value="SÃO PAULO-BR">SÃO PAULO-BR</option>
          </Select>
        </SectionContent>

        <ToggleSection onClick={() => toggleSectionVisibility('budgetRanges')}>
          <span>Faixa de orçamento</span>
          <ToggleArrow isOpen={sectionsVisibility.budgetRanges}>⬇️</ToggleArrow>
        </ToggleSection>
        <SectionContent isVisible={sectionsVisibility.budgetRanges}>
          {[content.budgetrange].map((range, index) => (
            <CheckboxGroup key={index}>
              <input
                type="checkbox"
                id={`budgetRanges-${index}`}
                name="budgetRanges"
                value={range}
                checked={userData.budgetRanges.includes(range)}
                onChange={handleCheckboxChange}
              />
              <label htmlFor={`budgetRanges-${index}`}>{range}</label>
            </CheckboxGroup>
          ))}
        </SectionContent>

        <ToggleSection onClick={() => toggleSectionVisibility('airlinePreference')}>
          <span>Companhia Aérea - Plano de fidelidade</span>
          <ToggleArrow isOpen={sectionsVisibility.airlinePreference}>⬇️</ToggleArrow>
        </ToggleSection>
        <SectionContent isVisible={sectionsVisibility.airlinePreference}>
          <Select
            id="airlinePreference"
            name="airlinePreference"
            value={userData.airlinePreference[0] || ''}
            onChange={(e) => setUserData(prev => ({ ...prev, airlinePreference: [e.target.value] }))}
          >
            <option value="">{content.airlinevalue}</option>
            <option value="Nenhuma">Nenhuma</option>
            <option value="TAP Air Portugal">TAP Air Portugal</option>
            <option value="Ryanair">Ryanair</option>
            <option value="EasyJet">EasyJet</option>
            <option value="Azores Airlines (SATA)">Azores Airlines (SATA)</option>
            <option value="Iberia">Iberia</option>
            <option value="Lufthansa">Lufthansa</option>
            <option value="British Airways">British Airways</option>
            <option value="Air France">Air France</option>
            <option value="KLM">KLM</option>
            <option value="Swiss International Air Lines">Swiss International Air Lines</option>
            <option value="Austrian Airlines">Austrian Airlines</option>
            <option value="Alitalia (ITA Airways)">Alitalia (ITA Airways)</option>
            <option value="Brussels Airlines">Brussels Airlines</option>
            <option value="Scandinavian Airlines (SAS)">Scandinavian Airlines (SAS)</option>
            <option value="Norwegian Air Shuttle">Norwegian Air Shuttle</option>
            <option value="Vueling">Vueling</option>
            <option value="American Airlines">American Airlines</option>
            <option value="Delta Air Lines">Delta Air Lines</option>
            <option value="United Airlines">United Airlines</option>
            <option value="JetBlue Airways">JetBlue Airways</option>
            <option value="Emirates">Emirates</option>
            <option value="Qatar Airways">Qatar Airways</option>
            <option value="Turkish Airlines">Turkish Airlines</option>
            <option value="Ethiopian Airlines">Ethiopian Airlines</option>
            <option value="Air Canada">Air Canada</option>
          </Select>
        </SectionContent>

        <Button type="submit">Atualizar Perfil</Button>
          </Form>
          <StyledLink href="/premium-current-offers">Ver Ofertas Premium Atuais</StyledLink>
        </LeftSection>
        <RightSection>
          <Subtitle>Atualizar Senha</Subtitle>
          <form onSubmit={handlePasswordUpdate}>
            <PasswordInput
              type="password"
              placeholder="Nova Senha"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <Button type="submit" disabled={isUpdating}>
              {isUpdating ? "Updating..." : "Update Password"}
            </Button>
          </form>
          <Subtitle>Cancelar Assinatura</Subtitle>
          <CancelButton onClick={() => setIsModalOpen(true)}>Cancelar Assinatura</CancelButton>

          {/* Modal for cancelation options */}
          {isModalOpen && (
            <Modal 
              onClose={() => setIsModalOpen(false)} 
              onSelectOption={handleCancelSubscription} 
            />
          )}
        </RightSection>
      </div>
    </ProfileContainer>
  );
};

export default SecureProfilePage;