import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import LazyLoad from 'react-lazyload';
import Carousel from '../components/Carousel';
import MainCurrentOffers from '../components/MainCurrentOffers';
import Testimonials from '../components/Testimonials';
import SubscribePopup from '../components/SubscribePopup';
import contentPT from '../content/homepage/contentPT';
import contentBR from '../content/homepage/contentBR';
import LanguageContext from '../context/LanguageContext';

const Container = styled.div`
  padding: 0px;
`;

const HeroSection = styled.section`
  position: relative;
  height: 100vh; // Use viewport height for more responsive sizing
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align content to the top
  align-items: center;
  text-align: center;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 30rem;
  }
`;

const HeroText = styled.div`
  position: absolute;
  top: 2%; // Move title up
  left: 8%;
  right: 8%;
  text-align: center;
  color: #FFFFFF; /* Ensure text is visible on the images */
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem; // Add gap between elements
  @media (max-width: 1024px) {
    top: 8%;
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    top: 5%;
    gap: 1rem;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-size: 8vw;
  color: #FF5733; /* Primary color */
  text-transform: uppercase;
  margin-bottom: 1.5rem; // Reduce Margin
  margin-top: 2rem;
  text-shadow: 0.7rem -0.23rem 0.6rem rgba(15, 9, 9, 0.3); /* Adding shadow */
  @media (max-width: 1024px) {
    font-size: 10vw;
    margin-bottom: 0.75rem;
  }

  @media (max-width: 768px) {
    font-size: 12vw;
    margin-bottom: 0.5rem;
  } 
`;

const HeroTextP = styled.p`
  font-family: 'Nunito';
  font-size: 2rem;
  font-weight: 700;
  padding-top: 8.5rem;
  margin-bottom: 0.2rem;
  -webkit-text-stroke: 0.4px #EAF0F8; /* Adding text stroke */
  text-shadow: 
    -0.4px -0.4px 0 #EAF0F8,  
    0.4px -0.4px 0 #EAF0F8,
    -0.4px 0.4px 0 #EAF0F8,
    0.4px 0.4px 0 #EAF0F8; /* Fallback for other browsers */
  width: 80%; /* Adjust the width to make the box smaller */
  margin: 0 auto; /* Center the paragraph */
  letter-spacing: 0.1rem;
  @media (max-width: 1024px) {
    font-size: 1.5rem; // Decreased size for tablet
  }

  @media (max-width: 768px) {
    font-size: 1rem; // Further decreased size for mobile
  }
`;

const HeroButton = styled.button`
  font-family: 'Nunito';
  font-size: 1.4rem;
  font-weight: 700;
  color: #F39C12;
  background: #2980B9;
  border: none;
  padding: 1.1rem 1.4rem;
  border-radius: 1.5rem;
  cursor: pointer;
  margin-top: 0.2rem; // Reduced from 11rem

  &:hover {
    background: #F39C12;
    color: #0A1111;
  }

  @media (max-width: 1024px) {
    font-size: 1.2rem;
    padding: 0.9rem 1.2rem;
    margin-top: 5.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.7rem 1rem;
    margin-top: 5rem;
  }
`;

const ServicesSection = styled.section`
  text-align: center;
  padding: 2rem;
  background-color:  #EAF0F8;
  h2 {
    font-family: 'Poppins';
    font-size: 5vw;
    color: #2980B9;
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;

const ServiceCards = styled.div`
  display: grid;
  gap: 2rem;
  margin-top: 1rem;
  padding: 2rem;
  justify-content: center;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(250px, 300px));
    grid-template-rows: auto auto;
    grid-template-areas:
      "card1 card2 card3"
      ". card4 card5";
    
    & > *:nth-child(1) { grid-area: card1; transform: translateY(50px) translateX(-80px); }
    & > *:nth-child(2) { grid-area: card2; transform: translateY(10px) translateX(20px)}
    & > *:nth-child(3) { grid-area: card3; transform: translateY(50px); }
    & > *:nth-child(4) { grid-area: card4; transform: translateX(-250px); }
    & > *:nth-child(5) { grid-area: card5; transform: translateX(120px) translateY(-5px); }
  }
`;

const ServiceCard = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 32rem;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Sans Source Pro';
  font-size: 1.3rem;
  color: white;
  opacity: 0.8;

  &:nth-child(1),
  &:nth-child(5) {
    background-color: #2980B9; /* Blue */
  }

  &:nth-child(3),
  &:nth-child(4) {
    background-color: #FF5733; /* Orange */
  }

  &:nth-child(2) {
    background-color: #F39C12; /* Yellow */
  }
`;

const CurrentOffersSection = styled.section`
  margin: 2rem 0;
  text-align: center;
  h2 {
    font-family: 'Poppins';
    font-size: 5vw;
    color: #2980B9;
    padding: 2rem;
  }
`;

const TestimonialsSection = styled.section`
  text-align: center;
  background-color:  #EAF0F8;
  padding: 2rem;
  h2 {
    font-family: 'Nunito';
    font-size: 5vw;
    color: #2980B9;
  }
`;

const Homepage = () => {
  const {language} = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const debouncedOpenPopup = debounce(() => setIsPopupOpen(true), 300);
  const debouncedClosePopup = debounce(() => setIsPopupOpen(false), 300);

  const homepageImages = [
    "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FBack%20Cover%20-%20Messages.jpg?alt=media&token=818475f8-b58d-4a5c-b842-427edf535abc",
    "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-peng-liu-45946-169647.jpg?alt=media&token=89113832-d7cb-4505-bc9d-2941f86b2b5f",
    "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FCover-Backpackers1.jpg?alt=media&token=b3c690f1-b34e-41fa-94d5-3f52953e26f5",
    "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FCover-Egypt.jpg?alt=media&token=f0a56231-7db5-4169-8256-3b79143dd04b",
    "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-francesco-ungaro-2325446.jpg?alt=media&token=d3ace609-e9aa-47d9-b50f-375161610509",
    "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FCover-Beach2.jpg?alt=media&token=81caa3c2-e4ac-43ef-964c-6721d61e6ed9",
    "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fskyline.jpg?alt=media&token=e157174a-fa0b-4b4c-bb2f-e0af61ee2063",
  ];

  const testimonials = [
    {
      message: "Serviço simples mas eficaz. Muito bom!",
      author: "João Silva"
    },
    {
      message: "Algumas das poupanças são incríveis!",
      author: "Maria Madalena"
    },
    {
      message: "O serviço gratuito vale a pena.",
      author: "Simão Madeira"
    },
  ];

  return (
    <Container>
      <HeroSection>
        <Carousel imageUrls={homepageImages} />
        <HeroText>
          <Title>{content.title}</Title>
          <HeroTextP>{content.herotext}</HeroTextP>
          <HeroButton onClick={debouncedOpenPopup}>Quero ofertas incríveis!</HeroButton>
        </HeroText>
      </HeroSection>
      <ServicesSection>
        <h2>Subscritores Do <Highlight>Clube de Voos</Highlight> Têm</h2>
        <ServiceCards>
          <ServiceCard>
            <h4>{content.ofertasexclusivas.heading}</h4>
            <p>{content.ofertasexclusivas.text}</p>
          </ServiceCard>
          <ServiceCard>
            <h4>{content.promoções.heading}</h4>
            <p>{content.promoções.text}</p>
          </ServiceCard>
          <ServiceCard>
            <h4>{content.acesso.heading}</h4>
            <p>{content.acesso.text}</p>
          </ServiceCard>
          <ServiceCard>
            <h4>{content.tarifas.heading}</h4>
            <p>{content.tarifas.text}</p>
          </ServiceCard>
          <ServiceCard>
            <h4>{content.opções.heading}</h4>
            <p>{content.opções.text}</p>
          </ServiceCard>
        </ServiceCards>
      </ServicesSection>
      <CurrentOffersSection>
          <MainCurrentOffers />
      </CurrentOffersSection>
      <TestimonialsSection>
        <h2>O Que <Highlight>Eles/Elas</Highlight> Dizem</h2>
        <LazyLoad height={200} offset={100} once>
          <Testimonials testimonials={testimonials} />
        </LazyLoad>
      </TestimonialsSection>
      <SubscribePopup isOpen={isPopupOpen} onClose={debouncedClosePopup} />
    </Container>
  );
};

export default Homepage;
