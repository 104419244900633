//contentBR
const contentBR = {
    setstatus: "Um link de redefinição de senha foi enviado para o seu e-mail.",
    setstatus2: "Erro ao enviar o e-mail de redefinição. Verifique o e-mail.",
    seterror: "Por favor, verifique o seu e-mail antes de fazer login.",
    error1: "Sua conta não é premium.",
    error2: "Sua assinatura não está ativa.",
    error3: "Sua conta não está verificada.",
    user: "Usuário não encontrado no banco de dados.",
    usererror: "Usuário não encontrado.",
    passerror: "Senha incorreta.",
    password: "Senha:",
    forgot: "Esqueceu a senha?",
  }
  
  export default contentBR;
  