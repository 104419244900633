// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAWUNUOKvSUzC5oe2EhMYsWHi7pDWs90-Q",
  authDomain: "cdv---stagging-37d74.firebaseapp.com",
  projectId: "cdv---stagging-37d74",
  storageBucket: "cdv---stagging-37d74.appspot.com",
  messagingSenderId: "160020906127",
  appId: "1:160020906127:web:5ceefa7943420fd85a70fa",
  measurementId: "G-QVEEVDK5V4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app, 'southamerica-east1');
const storage = getStorage(app);

// We're exporting firestore as both 'firestore' and 'db' for flexibility
export { app, auth, firestore, functions, storage };
export const db = firestore;