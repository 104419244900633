import React, { useContext } from 'react';
import styled from 'styled-components';
import contentPT from '../content/privacypolicy/contentPT';
import contentBR from '../content/privacypolicy/contentBR';
import LanguageContext from '../context/LanguageContext';

const PrivacyPolicyPage = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const PrivacyPolicyContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const PrivacyPolicyHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const PrivacyPolicyTitle = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 45px;
  font-weight: 500;
  color: #2980b9;

  @media (max-width: 768px) {
    font-size: 35px;
  }
`;

const PrivacyPolicyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: #2980b9;
  margin-bottom: 1rem;
`;

const SectionContent = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 1.6;
`;

const List = styled.ul`
  margin-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const Highlight = styled.span`
  color: #FF5733;
`;

const PrivacyPolicy = () => {
  const {language} = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  return (
    <PrivacyPolicyPage>
      <PrivacyPolicyContainer>
      <PrivacyPolicyHeader>
          <PrivacyPolicyTitle>Política de Privacidade</PrivacyPolicyTitle>
        </PrivacyPolicyHeader>
        <PrivacyPolicyContent>
          <Section>
            <SectionTitle>1. Introdução</SectionTitle>
            <SectionContent>
              {content.intro}
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.info.heading}</SectionTitle>
            <SectionContent>
              {content.info.text}
              <List>
                <ListItem><strong>Nome:</strong> Para personalizar as nossas comunicações.</ListItem>
                <ListItem><strong>Endereço de Email:</strong> Para enviar a nossa newsletter e verificar a subscrição.</ListItem>
                <ListItem><strong>Preferências de Viagem:</strong> {content.info.list}</ListItem>
              </List>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.collection.heading}</SectionTitle>
            <SectionContent>
              <List>
                <ListItem>{content.collection.list1}</ListItem>
                <ListItem>{content.collection.list2}</ListItem>
              </List>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.usage.heading}</SectionTitle>
            <SectionContent>
              {content.usage.text}
              <List>
                <ListItem>{content.usage.list1}</ListItem>
                <ListItem>{content.usage.list2}</ListItem>
                <ListItem>{content.usage.list3}</ListItem>
                <ListItem>{content.usage.list3}</ListItem>
              </List>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.payment.heading}</SectionTitle>
            <SectionContent>
              {content.payment.text}
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.retention.heading}</SectionTitle>
            <SectionContent>
              {content.retention.text}
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.security.heading}</SectionTitle>
            <SectionContent>
              {content.security.text}
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.cookies.heading}</SectionTitle>
            <SectionContent>
              {content.cookies.text}
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.rights.heading}</SectionTitle>
            <SectionContent>
              {content.rights.text}
              <List>
                <ListItem>{content.rights.list1}</ListItem>
                <ListItem>{content.rights.list2}</ListItem>
                <ListItem>{content.rights.list3}</ListItem>
                <ListItem>{content.rights.list4}</ListItem>
              </List>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.changes.heading}</SectionTitle>
            <SectionContent>
              {content.changes.text}
              <br /><br />
              Última Atualização: 15 de Dezembro de 2024
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.contact.heading}</SectionTitle>
            <SectionContent>
              {content.contact.text}
              <br /><br />
              apoio@clubedevoos.com<br />
              <Highlight>Clube De Voos<br /></Highlight>
            </SectionContent>
          </Section>
        </PrivacyPolicyContent>
        
      <PrivacyPolicyHeader>
          <PrivacyPolicyTitle>{content.tctitle}</PrivacyPolicyTitle>
        </PrivacyPolicyHeader>
        <PrivacyPolicyContent>
          <Section>
            <SectionTitle>{content.refunds.heading}</SectionTitle>
            <SectionContent>
            <List>
                <ListItem>{content.refunds.list1}</ListItem>
                <ListItem>{content.refunds.list2}</ListItem>
              </List>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.mkt.heading}</SectionTitle>
            <SectionContent>
              <List>
                <ListItem>{content.mkt.list1}</ListItem>
                <ListItem>{content.mkt.list2}</ListItem>
              </List>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.promos.heading}</SectionTitle>
            <SectionContent>
              <List>
                <ListItem>{content.promos.list1}</ListItem>
              </List>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.airports.heading}</SectionTitle>
            <SectionContent>
              <List>
                <ListItem>{content.airports.list1}</ListItem>
                <ListItem>{content.airports.list2}</ListItem>
              </List>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.tcprices.heading}</SectionTitle>
            <SectionContent>
            {content.tcprices.text}
              <List>
                <ListItem>{content.tcprices.list1}</ListItem>
                <ListItem>{content.tcprices.list2}</ListItem>
              </List>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>{content.tcchanges.heading}</SectionTitle>
            <SectionContent>
              <List>
                <ListItem>{content.tcchanges.list1}</ListItem>
              </List>
                Última Atualização: 15 de Dezembro de 2024
            </SectionContent>
          </Section>
          </PrivacyPolicyContent>
      </PrivacyPolicyContainer>
    </PrivacyPolicyPage>
  );
};

export default PrivacyPolicy;
