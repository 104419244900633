import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const GlobalStyle = createGlobalStyle`
  .carousel-image-enter {
    opacity: 0;
  }
  .carousel-image-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in-out;
  }
  .carousel-image-exit {
    opacity: 1;
  }
  .carousel-image-exit-active {
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.height || '50rem'};
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 40rem;
  }

  @media (max-width: 768px) {
    height: 30rem;
  }
`;

const CarouselImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  animation: ${props => props.entering ? fadeIn : fadeOut} 1s ease-in-out;
`;

const Carousel = ({ imageUrls = [], height }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [nextImage, setNextImage] = useState(1);

  useEffect(() => {
    if (imageUrls.length > 1) {
      const interval = setInterval(() => {
        setCurrentImage(current => (current + 1) % imageUrls.length);
        setNextImage(next => (next + 1) % imageUrls.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [imageUrls]);

  if (!Array.isArray(imageUrls) || imageUrls.length === 0) {
    return <CarouselContainer height={height}>Loading...</CarouselContainer>;
  }

  return (
    <>
      <GlobalStyle />
      <CarouselContainer height={height} aria-live="polite">
        <CarouselImage 
          src={imageUrls[currentImage]} 
          alt={`Slide ${currentImage + 1}`}
          entering={true}
        />
        <CarouselImage 
          src={imageUrls[nextImage]} 
          alt={`Slide ${nextImage + 1}`}
          entering={false}
        />
      </CarouselContainer>
    </>
  );
};

export default Carousel;

