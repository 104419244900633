// contentPT.js

const contentPT = {
  labels: {
    homeAirport: "Escolhe o teu aeroporto de origem",
  },
  popup: {
    heading: "Subscreve ao",
    text: "Foi enviado um email de verificação. Por favor, verifica a tua caixa de correio electrónico e clica no link para ativar a subscrição.",
  },
  alert: "A submeter...",
  faqData: [
    {
          question: "Como funciona o Clube de Voos?",
          answer: "O Clube de Voos envia regularmente ofertas exclusivas e promoções personalizadas diretamente para a tua caixa de correio eletrónico. A nossa equipa pesquisa as melhores tarifas aéreas, incluindo erros de tarifas e descontos de última hora, para que possas economizar nas tuas viagens.",
    },
    {      
          question: "Qual é a diferença entre a subscrição gratuita e a Premium?",      
          answer: "Com a subscrição gratuita, recebes pelo menos 2 ofertas por mês para o teu aeroporto de partida. A subscrição Premium oferece não só mais ofertas como também promoções personalizadas incluindo ofertas relacionadas com programas de fidelidade para certas companhias aéreas e acesso exclusivo a tarifas de erro raras.",
    },
    {      
          question: "Os preços das tarifas de erro são garantidos?",
          answer: "As tarifas de erro são raras e resultam de erros de preço nas companhias aéreas. Embora muitas vezes as companhias aéreas honrem essas tarifas, não podemos garantir que o farão sempre. É sempre uma boa ideia esperar pela confirmação da reserva antes de fazeres outros planos de viagem. As tarifas de erro também de ser marcadas de imediato porque normalmente desaparecem em poucas horas.",
    },
    {      
          question: "Como posso personalizar as minhas preferências de viagem?",
          answer: "Os membros Premium podem personalizar as suas preferências de viagem através da sua conta no nosso site. Neste momento, podem atualizar o seu aeroporto de partida e definir a classe dos voos e a companhia aérea com a qual recebem pontos/milhas. Em breve, vamos adicionar: Destinos preferidos; Datas de viagem preferidas; Interesses (Desporto; Cultural; Relaxamento; etc...) e outros critérios para personalizarmos as ofertas de forma a que se adequem efetivamente às tuas preferências.",
    },      
    {
          question: "Com que frequência enviam emails?",
          answer: "Enviamos e-mails regularmente com as melhores ofertas que encontramos. A frequência dos e-mails pode variar, mas normalmente podes esperar receber entre 2 a 4 e-mails por mês para subscritores gratuitos e 1 a 3 por semana para subscritores Premium.",
    },
    {     
          question: "As ofertas são realmente tão boas quanto dizem?",
          answer: "Sim, a nossa equipa dedica-se a encontrar as melhores ofertas possíveis. No entanto, os erros de tarifa (Premium) desaparecem rapidamente, por isso recomendamos que aproveitem essas ofertas assim que as recebem.",
    },
    {      
          question: "A minha informação pessoal está segura?",
          answer: "Sim, levamos a tua privacidade muito a sério. Só recolhemos o teu nome e e-mail. O processamento de pagamentos é feito diretamente no ambiente seguro da Stripe, e não temos acesso a qualquer informação financeira fornecida durante esse processo. Protegemos as tuas informações pessoais e não partilhamos os teus dados com terceiros sem o teu consentimento.",
    },
    {      
          question: "O que acontece se eu encontrar um problema ou tiver uma dúvida?",
          answer: "Estamos aqui para ajudar! Se tiveres alguma dúvida ou encontrares algum problema, podes entrar em contacto connosco através do nosso email de suporte apoio@clubedevoos.com.",
    },
    {      
          question: "Posso mudar do plano gratuito para o Premium mais tarde?",
          answer: "Sim, podes mudar para a subscrição Premium a qualquer momento. Basta fazer o upgrade através do nosso site e começar a aproveitar os benefícios exclusivos imediatamente. Também podes mudar de Premium para gratuito a qualquer momento.",
    },
    {      
          question: "Se eu cancelar o plano Premium, perco todos os meus benefícios imediatamente?",
          answer: "Não, os benefícios Premium permanecem até ao final do período subscrito e os utilizadores podem também optar por manter a versão gratuita quando cancelam o Premium.",
    },
    {      
          question: "Vocês oferecem pacotes de férias?",
          answer: "De momento, o Clube de Voos foca-se somente em encontrar e enviar as melhores ofertas de voos. No entanto, estamos sempre a explorar novas formas de oferecer mais valor aos nossos membros, incluindo a possibilidade de pacotes de férias no futuro.",
    },    
    {  
          question: "Posso reservar os voos através do vosso site?",
          answer: "As ofertas que enviamos incluem links diretos para os sites das companhias aéreas, Skyscanner, Google Flights Search, ou agências de viagens onde podes reservar os voos. De momento não podes fazer as reservas diretamente no nosso site, mas é algo que podemos vir a implementar num futuro próximo.",
    },
    {      
          question: "Como posso aproveitar as ofertas que vocês enviam?",
          answer: "Para aproveitar as ofertas, basta clicar no link fornecido no email da oferta. Isso envia-te para o site onde podes fazer a reserva. Recomendamos que reserves o mais rápido possível, pois as tarifas aéreas podem mudar rapidamente especialmente para voos com datas mais próximas e definitivamente para erros de tarifa.",
    },
    {      
          question: "Podem encontrar voos específicos e/ou pacotes para mim?",
          answer: "O Clube de Voos foca-se em enviar as melhores ofertas e promoções que encontramos. Para os nossos membros Premium, oferecemos promoções personalizadas com base nas suas preferências de viagem, garantindo que recebam ofertas que melhor se adequem às suas necessidades. No entanto, não oferecemos um serviço de busca de voos ou pacotes de férias específicos.",
    },
    {      
          question: "Posso cancelar a minha subscrição a qualquer momento?",
          answer: "Sim, podes cancelar a subscrição a qualquer momento. Basta clicar no link de cancelamento no final de qualquer email que enviamos ou aceder à tua conta no nosso site e cancelar a subscrição (Premium).",
    },
  ],
};

export default contentPT;
