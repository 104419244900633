// In PrivateRoute.js

import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const PrivateRoute = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('User is signed in:', user.uid);
        try {
          const db = getFirestore();
          const userDoc = await getDoc(doc(db, 'subscribers', user.uid));

          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('User data:', userData);
            setIsAuthorized(
              userData.subscriptionType === 'Premium' &&
              userData.subscriptionStatus === 'Active' &&
              userData.isVerified === true
            );
          } else {
            console.log('User document not found');
            setIsAuthorized(false);
          }
        } catch (error) {
          console.error('Error checking user access:', error);
          setIsAuthorized(false);
        }
      } else {
        console.log('No user is signed in');
        setIsAuthorized(false);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  if (isLoading) {
    return <div>A carregar...</div>;
  }

  return isAuthorized ? children : <Navigate to="/unauthorized" replace />;
};

export default PrivateRoute;