// contentBR
const contentBR = {
    title: "Termos e Condições - Sorteio 10.000 Assinantes",
    highlight: "Clube De Voos",
    eligibility: {
      heading: "1. Elegibilidade",
      text: "Para participar, você deve ser assinante do plano vitalício do Clube De Voos e ter 18 anos ou mais. A cidadania não é um critério."
    },
    period: {
      heading: "2. Período de Participação",
      text: "O período de participação começa em 15/12/2024 e termina quando atingirmos os 10.000 assinantes vitalícios. O sorteio será realizado, no máximo, até 4 semanas após o término do período de participação."
    },
    prize: {
      heading: "3. Prêmio",
      text: "O prêmio principal consiste em:",
      feat1: "2 passagens de ida e volta em classe econômica ou executiva.",
      feat2: "Até 7 noites de estadia em um hotel 4 estrelas ou equivalente.",
      extra: "O Clube De Voos reserva-se o direito de oferecer um prêmio surpresa adicional a um segundo assinante selecionado aleatoriamente.",
    },
    selection: {
      heading: "4. Processo de Seleção",
      text: "O vencedor será escolhido aleatoriamente através de um processo justo e transparente (vídeo ao vivo no Youtube usando um Gerador de Números Aleatórios). Vamos garantir que todos os assinantes tenham a mesma chance de ganhar."
    },
    notification: {
      heading: "5. Notificação",
      text: "O vencedor será notificado por e-mail, já que temos seus dados como assinante."
    },
    limitation: {
      heading: "6. Limitações",
      text: "O vencedor terá 24 meses para escolher um pacote de viagem a partir das ofertas que enviamos aos nossos assinantes. As ofertas incluem voos em classe econômica e executiva. A acomodação será escolhida pelo Clube De Voos, garantindo um mínimo de 4 estrelas ou equivalente até um limite de 7 dias."
    },
    privacy: {
      heading: "7. Privacidade",
      text: "Não solicitamos informações pessoais adicionais. Utilizamos apenas o e-mail do vencedor para as reservas. O vencedor será responsável por gerenciar a reserva."
    },
    exemptions: {
      heading: "8. Isenções",
      text: "O Clube De Voos não fornece qualquer tipo de seguro. O vencedor é responsável por isso e por quaisquer outras responsabilidades decorrentes da viagem."
    },
    closing: {
      heading: "9. Disposições Finais",
      text: "Ao participar, você concorda com estes termos e com a política de privacidade do Clube de Voos. O Clube De Voos reserva-se o direito de alterar as condições do sorteio se necessário."
    },
    titlereferral: "Termos e Condições - Programa de Indicação",
    eligibilityreferral: {
      heading: "1. Elegibilidade",
      text: "Apenas assinantes do plano vitalício do Clube de Voos podem participar do programa de indicação."
    },
    howto: {
      heading: "2. Como Funciona",
      list1: "Cada participante recebe um código exclusivo de indicação. Para participar submeta o seu email aqui: https://clube-de-voos.formaloo.me/codigo-br",
      list2: "Novos assinantes devem usar o código durante a assinatura ao plano vitalício para que a indicação seja válida.",
      list3: "Cada indicação bem-sucedida gera 1 ponto para quem indicou.",
      list4: "Para resgatar um prêmio, o assinante deverá enviar um email para apoio@clubedevoos.com indicando o prêmio que deseja resgatar. O email precisa ser enviado do endereço de email associado à conta Premium com os pontos acumulados necessários para o resgate.",
    },
    prizereferral: {
      heading: "3. Prêmios",
      feat1: "3 pontos: Oferece uma assinatura vitalícia para outra pessoa. Basta enviar o e-mail do destinatário e o Clube de Voos ativará a assinatura vitalícia gratuitamente.",
      feat2: "10 pontos: 1 passagem gratuita (ida e volta). Valor máximo de R$ 3000. Pessoal e intransferível.",
      feat3: "15 pontos: Recebe um novo código de indicação que oferece R$ 150 de desconto para novos assinantes vitalícios que usarem o código. O código é válido por 24 meses após ser emitido e pode ser usado múltiplas vezes.",
      feat4: "20 pontos: 2 passagens gratuitas (ida e volta). Valor máximo de R$ 3000 por passagem. Uma das passagens deve obrigatoriamente ser para o assinante que atingiu os 20 pontos.",
    },
    limitationsreferral: {
      heading: "4. Limitações",
      feat1: "1. Ao usar os pontos para resgatar um prêmio, o total de pontos acumulados será automaticamente reduzido.",
      list1: "Exemplo: Um participante com 8 pontos que oferece uma assinatura vitalícia terá o total reduzido para 5 pontos.",
      feat2: "2. Os pontos acumulados têm validade de 24 meses.",
      list2: "Exemplo: Um ponto recebido em 1º de maio de 2025 deve ser utilizado até 1º de maio de 2027.",
      feat3: "3. O código de indicação com desconto de R$ 125 tem validade de 24 meses e pode ser usado um número ilimitado de vezes nesse período.",
      feat4: "4. O valor máximo dos prêmios relacionados às passagens não cobre custos adicionais, como seguros de viagem.",
    },
    fraud: {
      heading: "5. Fraude e Abuso",
      text: "O Clube de Voos se reserva o direito de suspender ou desqualificar participantes que violem os termos do programa ou abusem do sistema de indicações.",
    },
    changes: {
      heading: "6. Alterações no Programa",
      text: "O Clube de Voos pode modificar ou encerrar o programa a qualquer momento, notificando os participantes previamente. Caso o programa seja encerrado, todos os pontos acumulados permanecerão válidos por 24 meses a partir da data em que foram acumulados.",
    }
  };
  
  export default contentBR;  