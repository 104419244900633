import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Slide from '../components/Slide';

const CurrentOffersSection = styled.section`
  margin: 2rem 0;
  text-align: center;
  h2 {
    font-family: 'Poppins';
    font-size: 3vw;
    color: #2980B9;
    padding: 2rem;
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;
const MoreCurrentOffers = () => {
  const offers = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fbarcelona.jpg?alt=media&token=6221c23e-9f06-4b97-8818-54d9f732f935",
      details: "Ida e Volta - Fevereiro a Agosto 2025<br>Lisboa - Barcelona<br>De: 31€ a 48€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/bcn/20250308/20250312/?adultsv2=1&cabinclass=economy&childrenv2=&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1&utm_campaign=nova-oferta-de-lisboa-para-barcelona-espanha&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FPunta-Cana-Republica-Dominicana.jpg?alt=media&token=ed734259-2d88-4964-a16b-eb555be96dce",
      details: "Ida e Volta - Janeiro a Setembro 2025<br>Rio de Janeiro a Punta Cana<br>De: R$2055 a R$2369",
      externalLink: "https://www.skyscanner.net/transport/flights/gig/puj/20250324/20250330/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-rio-de-janeiro-para-punta-cana-republica-dominicana&utm_medium=email&utm_source=acumbamail",
    },  
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fagadir.webp?alt=media&token=c1905a0f-0f51-4d24-bac1-0408ec2ab522",
      details: "Ida e Volta - Fevereiro a Setembro 2025<br>Porto a Agadir<br>De: 62€ a 184€",
      externalLink: "https://www.skyscanner.net/transport/flights/opo/aga/20250228/20250305/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-porto-para-agadir-marrocos&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fjeddah.jpg?alt=media&token=4142c23c-9fbd-433d-9822-06e4de21ff27",
      details: "Ida e Volta - Janeiro a Maio 2025<br>Lisboa a Jeddah<br>De: 193 a 264€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/jed/20250511/20250516/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-lisboa-para-jeddah-arabia-saudita&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fbissau.jpg?alt=media&token=9e8801b0-f083-48d9-af31-5561854c408a",
      details: "Ida e Volta - Março a Maio 2025<br>Lisboa a Bissau<br>A partir de 260€",
      externalLink: "https://gr.skyscanner.com/transport/flights/lis/oxb/250322/250401/?adultsv2=1&cabinclass=economy&childrenv2=&currency=eur&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&previousCultureSource=COOKIE&redirectedFrom=www.skyscanner.com&ref=home&rtn=1&utm_campaign=nova-oferta-de-lisboa-para-bissau&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fbogota.jpeg?alt=media&token=74b384b2-5c0a-4088-8b03-e983bbbbc5e9",
      details: "Ida e Volta - Maio 202<br>Rio De Janeiro a Bogotá<br>De: R$1965 a R$2372",
      externalLink: "https://www.skyscanner.net/transport/flights/gig/bog/20250518/20250525/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-rio-de-janeiro-para-bogota-colombia&utm_medium=email&utm_source=acumbamail",
    },
  ];

  const handleButtonClick = (offer) => {
    if (offer.externalLink) {
      window.open(offer.externalLink, '_blank');
    } else {
      console.error("No valid externalLink for this offer.");
    }
  };

  return (
    <CurrentOffersSection>
      <h2>Outras Ofertas <Highlight>Premium</Highlight></h2>
      <LazyLoad height={200} offset={100} once>
        <Slide offers={offers} handleButtonClick={handleButtonClick} />
      </LazyLoad>
    </CurrentOffersSection>
  );
};

export default MoreCurrentOffers;