// contentBR.js

const contentBR = {
    labels: {
      homeAirport: "Escolha seu aeroporto de origem",
    },
    popup: {
      heading: "Inscreva-se no",
      text: "Um e-mail de verificação foi enviado. Por favor, verifique sua caixa de entrada e clique no link para ativar a inscrição.",
    },
    alert: "Enviando...",
    faqData: [
      {
        question: "Como funciona o Clube de Voos?",
        answer: "O Clube de Voos envia regularmente ofertas exclusivas e promoções personalizadas diretamente para sua caixa de entrada. Nossa equipe pesquisa as melhores tarifas aéreas, incluindo erros de tarifas e descontos de última hora, para que você possa economizar em suas viagens.",
      },
      {      
        question: "Qual é a diferença entre a inscrição gratuita e a Premium?",      
        answer: "Com a inscrição gratuita, você recebe pelo menos 2 ofertas por mês para o seu aeroporto de partida. A inscrição Premium oferece não só mais ofertas, mas também promoções personalizadas, incluindo ofertas relacionadas a programas de fidelidade para certas companhias aéreas e acesso exclusivo a tarifas de erro raras.",
      },
      {      
        question: "Os preços das tarifas de erro são garantidos?",
        answer: "As tarifas de erro são raras e resultam de erros de preço nas companhias aéreas. Embora muitas vezes as companhias aéreas honrem essas tarifas, não podemos garantir que o farão sempre. É sempre uma boa ideia esperar pela confirmação da reserva antes de fazer outros planos de viagem. Tarifas de erro devem ser reservadas imediatamente, pois geralmente desaparecem em poucas horas.",
      },
      {      
        question: "Como posso personalizar minhas preferências de viagem?",
        answer: "Os membros Premium podem personalizar suas preferências de viagem através da conta em nosso site. Atualmente, podem atualizar o aeroporto de partida e definir a classe dos voos e a companhia aérea com a qual acumulam pontos/milhas. Em breve, vamos adicionar: Destinos preferidos; Datas de viagem preferidas; Interesses (Esporte, Cultura, Relaxamento, etc.) e outros critérios para personalizar ainda mais as ofertas.",
      },      
      {
        question: "Com que frequência enviam e-mails?",
        answer: "Enviamos e-mails regularmente com as melhores ofertas que encontramos. A frequência dos e-mails pode variar, mas normalmente você pode esperar receber entre 2 a 4 e-mails por mês para assinantes gratuitos e 1 a 3 por semana para assinantes Premium.",
      },
      {     
        question: "As ofertas são realmente tão boas quanto dizem?",
        answer: "Sim, nossa equipe dedica-se a encontrar as melhores ofertas possíveis. No entanto, as tarifas de erro (Premium) desaparecem rapidamente, então recomendamos que você aproveite essas ofertas assim que as receber.",
      },
      {      
        question: "Minhas informações pessoais estão seguras?",
        answer: "Sim, levamos sua privacidade muito a sério. Só coletamos seu nome e e-mail. O processamento de pagamentos é feito diretamente no ambiente seguro da Stripe, e não temos acesso a qualquer informação financeira fornecida durante o processo. Protegemos suas informações pessoais e não compartilhamos seus dados com terceiros sem seu consentimento.",
      },
      {      
        question: "O que acontece se eu tiver um problema ou uma dúvida?",
        answer: "Estamos aqui para ajudar! Se tiver alguma dúvida ou encontrar algum problema, você pode entrar em contato conosco através do nosso e-mail de suporte apoio@clubedevoos.com.",
      },
      {      
        question: "Posso mudar da versão gratuita para o Premium mais tarde?",
        answer: "Sim, você pode mudar para a inscrição Premium a qualquer momento. Basta fazer o upgrade através do nosso site e começar a aproveitar os benefícios exclusivos imediatamente. Também pode mudar de Premium para gratuito a qualquer momento.",
      },
      {      
        question: "Se eu cancelar o plano Premium, perco todos os meus benefícios imediatamente?",
        answer: "Não, os benefícios Premium permanecem até o final do período assinado e os usuários podem optar por manter a versão gratuita quando cancelam o Premium.",
      },
      {      
        question: "Vocês oferecem pacotes de férias?",
        answer: "No momento, o Clube de Voos foca-se somente em encontrar e enviar as melhores ofertas de voos. No entanto, estamos sempre explorando novas formas de oferecer mais valor aos nossos membros, incluindo a possibilidade de pacotes de férias no futuro.",
      },    
      {  
        question: "Posso reservar os voos através do site?",
        answer: "As ofertas que enviamos incluem links diretos para os sites das companhias aéreas, Skyscanner, Google Flights Search, ou agências de viagens onde você pode reservar os voos. Atualmente, não é possível fazer as reservas diretamente no nosso site, mas é algo que podemos implementar no futuro.",
      },
      {      
        question: "Como posso aproveitar as ofertas que vocês enviam?",
        answer: "Para aproveitar as ofertas, basta clicar no link fornecido no e-mail. Isso o enviará para o site onde poderá fazer a reserva. Recomendamos reservar o quanto antes, pois as tarifas aéreas podem mudar rapidamente, especialmente para voos com datas mais próximas e tarifas de erro.",
      },
      {      
        question: "Podem encontrar voos específicos e/ou pacotes para mim?",
        answer: "O Clube de Voos se concentra em enviar as melhores ofertas e promoções que encontramos. Para nossos membros Premium, oferecemos promoções personalizadas com base nas preferências de viagem, garantindo que recebam ofertas que melhor se adequem às suas necessidades. No entanto, não oferecemos um serviço de busca de voos ou pacotes específicos.",
      },
      {      
        question: "Posso cancelar minha inscrição a qualquer momento?",
        answer: "Sim, você pode cancelar a inscrição a qualquer momento. Basta clicar no link de cancelamento no final de qualquer e-mail que enviamos ou acessar sua conta em nosso site para cancelar a inscrição (Premium).",
      },
    ],
  };
  
  export default contentBR;
  