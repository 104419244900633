// contentBR.js

const contentBR = {
    title: "Pagamento efetuado! Obrigado!",
    titlebreak: "Vamos finalizar o perfil!",
    subtitle: "Queremos saber mais sobre suas preferências de viagem para enviar ofertas personalizadas!",
    labels: {
      homeAirport: "Escolha seu aeroporto de origem",
    },
    messages: {
      profileUpdateError: "Ocorreu um erro ao atualizar seu perfil:",
      emailConfirmation: "Obrigado! Seu perfil foi atualizado com sucesso. Agora só falta confirmar o email! Enviamos um email com um link que ativa esta conta para começarmos logo a enviar ofertas irresistíveis!",
      userNotFound: "Usuário não encontrado. Por favor, garanta que está usando o email associado à sua assinatura.",
    },
    submitButton: {
      submitting: "Enviando...",
    },
    budgetRanges: [
      "Viajante Econômico",
      "Viajante de Médio Alcance",
      "Viajante de Luxo",
    ],
  };
  
  export default contentBR;
  