import React, { useState, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import * as Yup from 'yup';
import contentPT from '../content/loginPopup/contentPT';
import contentBR from '../content/loginPopup/contentBR';
import LanguageContext from '../context/LanguageContext';

const LoginPopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoginPopupContainer = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 0.55rem;
  max-width: 23rem;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff0000;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  line-height: 1;

  &:hover {
    background-color: #cc0000;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const StyledField = styled(Field)`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledButton = styled.button`
  background: #2980b9;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #3498db;
  }
`;

const ErrorText = styled.div`
  color: #ff0000;
  margin-bottom: 1rem;
  text-align: center;
`;

const ForgotPasswordLink = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  color: blue;

  &:hover {
    color: darkblue;
  }
`;

const ResetPasswordPopup = ({ onClose }) => {
  const { language } = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  return (
    <LoginPopupOverlay onClick={onClose}>
      <LoginPopupContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <h2>Redefinir Senha</h2>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            email: Yup.string().email('Email inválido').required('Email é obrigatório'),
          })}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            try {
              const auth = getAuth();
              await sendPasswordResetEmail(auth, values.email);
              setStatus(content.setstatus);
              setTimeout(onClose, 3000); // Close after 3 seconds
            } catch (error) {
              setStatus(content.setstatus);
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <FormGroup>
                <StyledLabel htmlFor="email">Email:</StyledLabel>
                <Field as={StyledField} type="email" id="email" name="email" />
                <ErrorMessage name="email" component={ErrorText} />
              </FormGroup>
              <StyledButton type="submit" disabled={isSubmitting}>
                Enviar Link de Redefinição
              </StyledButton>
              {status && <ErrorText>{status}</ErrorText>}
            </Form>
          )}
        </Formik>
      </LoginPopupContainer>
    </LoginPopupOverlay>
  );
};

const LoginPopup = ({ isOpen, onClose, onLoginSuccess }) => {
  const { language } = useContext(LanguageContext); // Retrieve language context
  const content = language === 'BR' ? contentBR : contentPT;
  const [showResetPopup, setShowResetPopup] = useState(false);

  const handleResetClick = (e) => {
    e.preventDefault();
    setShowResetPopup(true);
  };

  const handleResetClose = () => {
    setShowResetPopup(false);
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Email inválido').required('Obrigatório'),
    password: Yup.string().required('Obrigatório'),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    try {
      console.log('Attempting login with email:', values.email);
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
      const user = userCredential.user;
      console.log('User authenticated:', user.uid);

      if (!user.emailVerified) {
        console.log('Email not verified');
        setErrors(content.seterror);
        setSubmitting(false);
        return;
      }

      const db = getFirestore();
      const userDoc = await getDoc(doc(db, 'subscribers', user.uid));
      console.log('User document:', userDoc.data());

      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data:', userData);
        if (userData.subscriptionType === 'Premium' && userData.subscriptionStatus === 'Active' && userData.isVerified === true) {
          console.log('Login successful, calling onLoginSuccess');
          onLoginSuccess();
        } else {
          console.log('Access denied: Not premium, not active, or not verified');
          let errorMessage = 'Acesso negado. ';
          if (userData.subscriptionType !== 'Premium') errorMessage += (content.error1);
          if (userData.subscriptionStatus !== 'Active') errorMessage += (content.error2);
          if (!userData.isVerified) errorMessage += (content.error3);
          setErrors({ password: errorMessage });
        }
      } else {
        console.log('User document not found in Firestore');
        setErrors({ password: (content.user) });
      }
    } catch (error) {
      console.error('Login error:', error);
      let errorMessage = 'Falha no login: ';
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage += (content.usererror);
          break;
        case 'auth/wrong-password':
          errorMessage += (content.passerror);
          break;
        case 'auth/invalid-email':
          errorMessage += 'Email inválido.';
          break;
        case 'auth/user-disabled':
          errorMessage += 'Conta desativada.';
          break;
        default:
          errorMessage += error.message;
      }
      setErrors({ password: errorMessage });
    }
    setSubmitting(false);
  };

  return (
    <LoginPopupOverlay onClick={onClose}>
      <LoginPopupContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <h2>Entrar</h2>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <FormGroup>
                <StyledLabel htmlFor="email">Email:</StyledLabel>
                <StyledField type="email" name="email" />
                <ErrorMessage name="email" component={ErrorText} />
              </FormGroup>
              <FormGroup>
                <StyledLabel htmlFor="password">{content.password}</StyledLabel>
                <StyledField type="password" name="password" />
                <ErrorMessage name="password" component={ErrorText} />
              </FormGroup>
              {errors.password && <ErrorText>{errors.password}</ErrorText>}
              <StyledButton type="submit" disabled={isSubmitting}>
                Entrar
              </StyledButton>
            </Form>
          )}
        </Formik>
        <p>
          <ForgotPasswordLink onClick={handleResetClick}>
            {content.forgot}
          </ForgotPasswordLink>
        </p>
      </LoginPopupContainer>
      {showResetPopup && <ResetPasswordPopup onClose={handleResetClose} />}
    </LoginPopupOverlay>
  );
};

export default LoginPopup;
