//contentPT
const contentPT = {
  title: "VOA CONNOSCO",
  herotext: "As melhores promoções de voos a aterrar direto no teu email Ofertas personalizadas para voar mais e gastar menos",
  ofertasexclusivas: {
    heading: "Ofertas Exclusivas de Voos",
    text: "Recebe acesso a ofertas de voos exclusivas na tua caixa de email."
  },
  promoções: {
    heading: "Promoções Personalizadas (Exclusivo para Membros Premium)",
    text: "Promoções de voos personalizadas com base nas preferências de viagens: Destinos; Datas; Acontecimentos."
  },
  acesso: {
    heading: "Acesso Antecipado a Ofertas (Exclusivo para Membros Premium)",
    text: "Recebe notificações sobre ofertas de voos antes de serem disponibilizadas aos restantes subscritores."
  },
  tarifas: {
    heading: "Tarifas de Erro e Descontos de Última Hora",
    text: "Alertas sobre tarifas de erro raras e descontos significativos de última hora."
  },
  opções: {
    heading: "Opções Acessíveis A Todos",
    text: "Aderir ao Clube de Voos é grátis! Subscritores Premium podem aderir pela módica quantia de 45€ por ano ou 1 pagamento único de 99€ para acesso vitalício a todos os benefícios Premium."
  },
};

export default contentPT;
