import React from 'react';
import DOMPurify from 'dompurify';
import styled from 'styled-components';

const CurrentOffers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.2rem 0;
`;

const OfferContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 1.8rem;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr; /* Stack offers in a single column on smaller screens */
    justify-items: center;
  }
`;

const Offer = styled.div`
  position: relative;
  width: 90%;
  margin: 0 1rem;
  border-radius: 0.6rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 1024px) {
    width: 60%;
    max-width: 30rem;
    margin: 1rem 0;
  }

  img {
    width: 100%;
    height: 20rem; /* Set a fixed height */
    object-fit: cover; /* Ensure the image covers the area while maintaining aspect ratio */
    display: block;
    border-radius: 1rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Ensure the overlay covers the entire container */
    background: rgba(0, 0, 0, 0.01);
    z-index: 1;
    border-radius: 1rem;
  }

  h3,
  p,
  button {
    position: relative;
    z-index: 2;
  }

  h3 {
    position: absolute;
    top: 15%;
    width: 100%;
    color: white;
    text-align: center;
    margin: 0;
    font-size: 1.5rem;
  }

  p {
    position: absolute;
    top: 64%;
    width: 100%;
    color: white;
    text-align: center;
    margin: 0;
    font-weight: 600;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.4);
  }

  button {
    position: absolute;
    top: 82%;
    background-color: #FF5733;
    color: white;
    border: none;
    padding: 0.8rem 1rem;
    cursor: pointer;
    border-radius: 0.3rem;
    margin-top: 0.6rem;

    &:hover {
      background-color: #f39c12;
    }
  }
`;

const Slide = ({ offers, handleButtonClick }) => {
  return (
    <CurrentOffers>
      <OfferContainer>
        {offers.map((offer, index) => (
          <Offer key={index}>
            <img src={offer.image} alt={offer.destination} />
            <h3>{offer.destination}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(offer.details),
              }}
            ></p>
            <button
              onClick={() => handleButtonClick(offer)}
              disabled={!offer.externalLink}
            >
              Ver Mais
            </button>
          </Offer>
        ))}
      </OfferContainer>
    </CurrentOffers>
  );
};

Slide.defaultProps = {
  handleButtonClick: (offer) => {
    if (offer.externalLink) {
      window.open(offer.externalLink, '_blank');
    } else {
      console.error("No valid externalLink for this offer.");
    }
  },
};

export default Slide;