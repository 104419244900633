import styled from 'styled-components';

const PageContainer = styled.div`
  background-color: #EAF0F8;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Hero = styled.section`
  position: relative;
  width: 100%;
  height: 40rem;
  background-color: #2980B9;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 150px; /* Adjust as necessary */
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    height: 25rem;
    font-size: 1.2rem;
  }

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  p {
    font-family: 'Nunito', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

const Section = styled.section`
  width: 100%;
  max-width: 900px;
  margin: 2rem 0;
  padding: 1rem;
  gap: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    font-family: 'Nunito', sans-serif;
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-family: 'Nunito', sans-serif;
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #2980B9;
  }

  p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
    margin: 1rem 0;
  }
.gif-gratuito {
    max-height: 20rem;
    object-fit: cover;
  }

  .gif-premium {
    max-height: 30rem;
    object-fit: contain;
  }

    .gif-oferta {
    max-height: 20rem;
    object-fit: contain;
  }

   .gif-win {
    max-height: 20rem;
    object-fit: cover;
  }

  iframe {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 25rem; /* Controls the max height for the iframe */
    border-radius: 10px; /* Optional: add rounded corners */
    margin: 1rem 0;
    border: none;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  box-sizing: border-box;

  /* Stack sections vertically on mobile */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  flex: 1 1 15%;
  max-width: 16rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.15rem;
  position: relative;
  img {
    border-radius: 15%;
  }

  /* Hide LeftSection on mobile */
  @media (max-width: 768px) {
    display: none;
  } 
`;

const MiddleSection = styled.div`
  flex: 1 1 60%;
  margin: 0 1rem;
`;

const RightSection = styled.div`
  flex: 1 1 15%;
  padding: 0.3rem;

  /* Move RightSection to end on mobile */
  @media (max-width: 768px) {
    order: 2;
    margin-top: 1.5rem;
  }
`;

const StickyColumn = styled.div`
  position: sticky;
  top: 10rem;
  
  h3 {
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #ff5733;
    text-align: center;
  }

  @media (max-width: 768px) {
    position: relative;
    top: 0;
  }
`;

const StripePriceTable = styled.div`
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  iframe {
    width: 100%;
    border: none;
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;
const LandingPage = () => {
  return (
    <PageContainer>
      <Hero>
        <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FClube%20de%20Voos%20Logo%20-%20(Accent%20CDV).png?alt=media&token=003e6a0d-6a67-4f66-a81c-6c8c9b29f10f" alt="Clube De Voos Logo" /> {/* Logo linking to the main site */}
        <h1>Mergulhe no Mundo com o Clube De Voos!</h1>
        <p>Ofertas de voos incríveis diretamente na sua caixa de entrada!</p>
      </Hero>

      <ContentContainer>
        <LeftSection>
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FBack%20Cover%20-%20Messages.jpg?alt=media&token=818475f8-b58d-4a5c-b842-427edf535abc" alt="Travel Inspiration" /> {/* Image for emotional connection */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-peng-liu-45946-169647.jpg?alt=media&token=89113832-d7cb-4505-bc9d-2941f86b2b5f" alt="Travel Inspiration" /> {/* Image for adrenaline */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FCover-Egypt.jpg?alt=media&token=f0a56231-7db5-4169-8256-3b79143dd04b" alt="Travel Inspiration" /> {/* Image for excitement */}
          <StripePriceTable>
            <script src="https://js.stripe.com/v3/pricing-table.js" async></script>
            {
              <stripe-pricing-table
              pricing-table-id="prctbl_1QFDhEH23jJ1vudCGj37njnB"
              publishable-key="pk_live_51PYi94H23jJ1vudC9HRdeNGHRh57YQzrN32vRmpz7xP3HQCiyijYaPnJgPaihbMdF7dFKJVO7oSKN95iUlzT3EZK008TKMTSHk"
            ></stripe-pricing-table>
            }
          </StripePriceTable>
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FCover-Backpackers1.jpg?alt=media&token=b3c690f1-b34e-41fa-94d5-3f52953e26f5" alt="Travel Inspiration" /> {/* Image for emotional connection */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-frans-van-heerden-201846-624015.jpg?alt=media&token=9a0a0bf0-689d-43c9-bb42-b79e0235fb22" alt="Travel Inspiration" /> {/* Image for aspiration */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-pixabay-163872.jpg?alt=media&token=1e4e8926-6954-4755-a014-7ba639e7b748" alt="Travel Inspiration" /> {/* Image for dreaming */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fskyline.jpg?alt=media&token=e157174a-fa0b-4b4c-bb2f-e0af61ee2063" alt="Travel Inspiration" /> {/* Image for emotional connection */}
          <StripePriceTable>
            <script src="https://js.stripe.com/v3/pricing-table.js" async></script>
            {
              <stripe-pricing-table
              pricing-table-id="prctbl_1QFDhEH23jJ1vudCGj37njnB"
              publishable-key="pk_live_51PYi94H23jJ1vudC9HRdeNGHRh57YQzrN32vRmpz7xP3HQCiyijYaPnJgPaihbMdF7dFKJVO7oSKN95iUlzT3EZK008TKMTSHk"
            ></stripe-pricing-table>
            }
          </StripePriceTable>
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-ekrulila-2218344.jpg?alt=media&token=11fa0b4a-e5ea-4708-bfbc-1e54f2c1319e" alt="Travel Inspiration" /> {/* Image for emotional connection */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-david-bartus-43782-586687.jpg?alt=media&token=af4af989-2153-44ef-af7e-ac510e73c67d" alt="Travel Inspiration" /> {/* Image for emotional connection */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-vince-2147487.jpg?alt=media&token=7640c262-d395-4ce3-9f90-8afd9e95bf63" alt="Travel Inspiration" /> {/* Image for emotional connection */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FCover-Beach2.jpg?alt=media&token=81caa3c2-e4ac-43ef-964c-6721d61e6ed9" alt="Travel Inspiration" /> {/* Image for emotional connection */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FCover-Bus.jpg?alt=media&token=7b636b01-3a29-4be5-9e2c-b477a500e877" alt="Travel Inspiration" /> {/* Image for emotional connection */}
          <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FBungee%20Jump.jpg?alt=media&token=62301fee-df2b-406f-ba83-b5b4ed128717" alt="Travel Inspiration" /> {/* Image for emotional connection */}
        </LeftSection>
        <MiddleSection>
          <Section>
            <h2>Você sonha em viajar?</h2>
            <p>
              Você é do tipo que não se contenta em conhecer apenas o bar da esquina, e sonha em tomar uma cerveja em Cancún?
            </p>
            <p>
              Ou quem sabe, você já se aventurou em algumas viagens locais, mas agora deseja algo mais... internacional?
            </p>
            <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-pixabay-38238.jpg?alt=media&token=ccce889c-c526-47e0-adec-b5a76714fe62" alt="Travel Inspiration" /> {/* Image for emotional connection */}
            <p>
            Acredita que os preços das passagens para os EUA estão mais altos que os índices de poluição? 
            </p>
            <p>
            A gente entende! No Clube De Voos, transformamos seu sonho de viajar em realidade sem deixar seu bolso mais vazio que o seu estoque de férias. Com nossos alertas de voos baratos, você pode finalmente conquistar o mundo (ou pelo menos o continente).
            </p>    
            <p>
            E a melhor parte? Você só paga uma vez e garante ofertas incríveis para toda a vida!
            </p>    
          </Section>

          <Section>
            <h2>O Que Oferecemos 🎁</h2>
            <h3>💼 Para os Membros Grátis</h3>
            <p>Totalmente grátis (para sempre)! Teste nosso serviço sem custo e receba ofertas que vão te fazer repensar aquele café que você toma todo dia na esquina.</p>
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Ffree-gif.gif?alt=media&token=4e6daf2c-623c-44c7-92e4-cb31a59b7979" 
              alt="Benefícios Membros Gratuitos" 
              className="gif-gratuito" 
            />
            <h3>🌟 Para os nossos Membros Premium</h3>
            <p>
              Fares de Erro: Receba alertas de tarifas imbatíveis antes que desapareçam como suas esperanças de ganhar na loteria!
            </p>
            <p>Ofertas Personalizadas: Enviadas de acordo com suas preferências e planos de fidelidade – porque a gente sabe que você quer voar pra Paris, não pra Praia Grande!
            </p>
            <p>Novidades em Primeira Mão: Acesso a uma série de recursos novos conforme evoluímos, incluindo ofertas para eventos especiais e destinos específicos! Veja mais no nosso Road Map!
            </p>
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Faround%20the%20world%20-%20gif1.gif?alt=media&token=c010a660-b755-49e9-be20-79a6853b8ea0" 
              alt="Benefícios Membros Premium" 
              className="gif-premium" 
            />
          </Section>

          <Section>
            <h2><Highlight>Oferta Exclusiva!</Highlight></h2>
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fgift-gif.gif?alt=media&token=da912d9c-f509-4b36-bb2e-ec2492a17c82" 
              alt="Benefícios Membros Premium" 
              className="gif-oferta" 
            />
            <p>
              Use o seu código promocional e receba um desconto exclusivo. Por apenas R$470 (em vez de R$620), você garante acesso vitalício a todos os nossos serviços!
            </p>
            <p>
              Isso dá menos de R$1,30 por dia! Se você gastar R$10 na sua cerveja favorita toda semana, por um ano você teria desembolsado R$520! Então, que tal beber apenas uma cerveja a menos por semana e investir em sua próxima aventura?
            </p>
            <img src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-eberhardgross-534164.jpg?alt=media&token=4b3cdbea-3687-4e89-9c79-27ef680a8a19" alt="Exclusive Offer" /> {/* Image for exclusive offer */}
            <p>
              E aqui vai a melhor parte: Ao reservar apenas um voo, você vai economizar mais do que o preço do seu acesso vitalício! O que significa que você não só vai poder brindar à sua viagem de sonho, mas ainda vai ficar com dinheiro sobrando no bolso, tudo isso enquanto continua a receber ofertas de voos que vão fazer seus amigos ficarem verdes de inveja!
            </p>
            <p>
              Tá esperando o quê? O relógio tá correndo, e essa oportunidade não vai durar para sempre. Se você não aproveitar agora, vai ter que se contentar em ficar olhando fotos de viagens no Instagram dos amigos e ficar pensando: "Quem me mandou não assinar?!"
            </p>
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Ffireworks-gif.gif?alt=media&token=3d6c43ac-fe7a-4447-98d6-e710e5afcf3a" 
              alt="Fireworks" 
              className="gif-win"
            />
            <p>
              E atenção, porque aqui a sorte poderá estar a seu favor: ao se inscrever, você entra no sorteio de uma experiência épica. Assim que atingirmos 10k assinantes vitalícios, um sortudo vai ganhar um voo de ida e volta para dois, em qualquer lugar dos nossos e-mails Premium (pode ser a sua chance de conhecer a Disney sem vender um rim!), mais hospedagem em um hotel 4 estrelas escolhido por nós (sim, sim, pode incluir piscina). T&Cs Aqui!
            </p>
          </Section>
        </MiddleSection>

        <RightSection>
          <StickyColumn>
            <h3>Quero a Promoção!</h3>
            <StripePriceTable>
            <script src="https://js.stripe.com/v3/pricing-table.js" async></script>
            {
              <stripe-pricing-table
              pricing-table-id="prctbl_1QFDhEH23jJ1vudCGj37njnB"
              publishable-key="pk_live_51PYi94H23jJ1vudC9HRdeNGHRh57YQzrN32vRmpz7xP3HQCiyijYaPnJgPaihbMdF7dFKJVO7oSKN95iUlzT3EZK008TKMTSHk"
            ></stripe-pricing-table>
            }
          </StripePriceTable>
          </StickyColumn>
        </RightSection>
      </ContentContainer>
    </PageContainer>
  );
};

export default LandingPage;

