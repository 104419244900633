import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import contentPT from '../content/completeprofile/contentPT';
import contentBR from '../content/completeprofile/contentBR';
import LanguageContext from '../context/LanguageContext';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Obrigatório'),
  homeAirport: Yup.array()
    .min(1, 'Seleciona o teu aeroporto')
    .max(1, 'Seleciona apenas um aeroporto')
    .required('Obrigatório'),
  budgetRanges: Yup.array().min(1, 'Seleciona pelo menos uma faixa de orçamento'),
  interests: Yup.array().min(1, 'Seleciona pelo menos um interesse'),
  airlinePreference: Yup.array()
    .min(1, 'Seleciona uma companhia aérea')
    .max(1, 'Seleciona apenas uma companhia aérea')
    .required('Obrigatório'),
});

const CompleteProfileContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 3vw;
  font-weight: 600;
  color: #2980b9;
  text-align: center;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    font-size: 5vw;
  }
`;

const Subtitle = styled.h3`
  font-family: 'Nunito', sans-serif;
  font-size: 1.5vw;
  font-weight: 500;
  color: #ff5733;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  font-family: 'Nunito', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #2980b9;
`;

const StyledField = styled(Field)`
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Source Sans Pro', sans-serif;
`;

const ErrorText = styled.div`
  color: #ff5733;
  font-size: 0.9rem;
  margin-top: 0.3rem;
`;

const ToggleSection = styled.div`
  margin-bottom: 1rem;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #2980b9;
  display: flex;
  align-items: center;
`;

const ToggleArrow = styled.span`
  transition: transform 0.3s ease;
  margin-left: 1.2rem;
  ${props => props.isOpen && `
    transform: rotate(180deg);
  `}
`;

const SectionContent = styled.div`
  display: ${props => (props.isVisible ? 'grid' : 'none')};
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledButton = styled.button`
  background-color: #ff5733;
  color: #fff;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.6rem;
  font-family: 'Nunito', sans-serif;
  letter-spacing: 0.05rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f39c12;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const MessageContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  color: ${props => props.type === 'success' ? '#155724' : '#721c24'};
  background-color: ${props => props.type === 'success' ? '#d4edda' : '#f8d7da'};
`;

const homeAirport = [
  'LISBOA-PT', 'PORTO-PT', 'RIO DE JANEIRO-BR', 'SÃO PAULO-BR',
];

const airlines = [
  'Nenhuma', 'TAP Air Portugal', 'Ryanair', 'EasyJet', 'Azores Airlines (SATA)', 
  'Iberia', 'Lufthansa', 'British Airways', 'Air France', 'KLM', 
  'Swiss International Air Lines', 'Austrian Airlines', 'Alitalia (ITA Airways)', 
  'Brussels Airlines', 'Scandinavian Airlines (SAS)', 'Norwegian Air Shuttle', 
  'Vueling', 'American Airlines', 'Delta Air Lines', 'United Airlines', 
  'JetBlue Airways', 'Emirates', 'Qatar Airways', 'Turkish Airlines', 
  'Ethiopian Airlines', 'Air Canada'
];
const CompleteProfile = () => {
  const {language} = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  const budgetRanges = content.budgetRanges;
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [sectionsVisibility, setSectionsVisibility] = useState({
    homeAirport: false,
    budgetRanges: false,
    travelCompanions: false,
    airlinePreference: false,
  });

  useEffect(() => {
    const auth = getAuth();
    setUser(auth.currentUser);
  }, []);

  const toggleSectionVisibility = (section) => {
    setSectionsVisibility((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Find the user document by email
      const userQuery = query(collection(firestore, 'subscribers'), where('email', '==', values.email));
      const userSnapshot = await getDocs(userQuery);
  
      if (userSnapshot.empty) {
        console.error('User not found for email:', values.email);
        setMessage({ type: 'error', text: content.messages.userNotFound });
        return;
      }
  
      const userDoc = userSnapshot.docs[0];
      const userId = userDoc.id;
  
      console.log('Updating document for user ID:', userId);
  
      // Update the existing document in Firestore
      await updateDoc(doc(firestore, 'subscribers', userId), {
        ...values,
        subscriptionType: 'Premium', // Ensure this is set to Premium
        subscriptionStatus: 'Active',
        updatedAt: new Date(),
        premiumSetupNeeded: false,
      });
  
      console.log('Document updated successfully');
      setMessage({ type: 'success', text: 'Perfil atualizado com sucesso!' });
      alert(content.messages.emailConfirmation);
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage({ type: 'error', text: `${content.messages.profileUpdateError} ${error.message}` });
    }
  
    setSubmitting(false);
  };

  return (
    <CompleteProfileContainer>
      <Title>{content.title} <br />{content.titlebreak}</Title>
      <Formik
        initialValues={{
          email: user?.email || '',
          homeAirport: [],
          budgetRanges: [],
          travelCompanions: [],
          airlinePreference: []
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, errors, touched }) => (
          <StyledForm>
          <FormGroup>
            <StyledLabel htmlFor="email">Email</StyledLabel>
            <StyledField type="email" name="email" id="email" placeholder="Email" />
            {errors.email && touched.email && <ErrorText>{errors.email}</ErrorText>}
          </FormGroup>

          <ToggleSection onClick={() => toggleSectionVisibility('homeAirport')}>
              <StyledLabel>{content.labels.homeAirport}</StyledLabel>
              <ToggleArrow isOpen={sectionsVisibility.homeAirport}>⬇️</ToggleArrow>
            </ToggleSection>
            <SectionContent isVisible={sectionsVisibility.homeAirport}>
              {homeAirport.map((airport, index) => (
                <CheckboxGroup key={index}>
                  <Field type="checkbox" name="homeAirport" value={airport} />
                  <label>{airport}</label>
                </CheckboxGroup>
              ))}
              {errors.homeAirport && touched.homeAirport && <ErrorText>{errors.homeAirport}</ErrorText>}
            </SectionContent>

          {/* Title for Budget, Interests, and Airline Preferences */}
          <Subtitle>{content.subtitle}</Subtitle>

          <ToggleSection onClick={() => toggleSectionVisibility('budgetRanges')}>
            <StyledLabel>Faixa de orçamento</StyledLabel>
            <ToggleArrow isOpen={sectionsVisibility.budgetRanges}>⬇️</ToggleArrow>
          </ToggleSection>
          <SectionContent isVisible={sectionsVisibility.budgetRanges}>
            {budgetRanges.map((range, index) => (
              <CheckboxGroup key={index}>
                <Field type="checkbox" name="budgetRanges" value={range} />
                <label>{range}</label>
              </CheckboxGroup>
            ))}
            {errors.budgetRanges && touched.budgetRanges && <ErrorText>{errors.budgetRanges}</ErrorText>}
          </SectionContent>

          <ToggleSection onClick={() => toggleSectionVisibility('airlinePreference')}>
            <StyledLabel>Companhias Aéreas - Programa de fidelidade</StyledLabel>
            <ToggleArrow isOpen={sectionsVisibility.airlinePreference}>⬇️</ToggleArrow>
          </ToggleSection>
          <SectionContent isVisible={sectionsVisibility.airlinePreference}>
            {airlines.map((airline, index) => (
              <CheckboxGroup key={index}>
                <Field type="checkbox" name="airlinePreference" value={airline} />
                <label>{airline}</label>
              </CheckboxGroup>
            ))}
            {errors.airlinePreference && touched.airlinePreference && <ErrorText>{errors.airlinePreference}</ErrorText>}
          </SectionContent>

          <StyledButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? content.submitButton.submitting : 'Enviar'}
          </StyledButton>
        </StyledForm>
        )}
      </Formik>
      {message && (
        <MessageContainer type={message.type}>
          {message.text}
        </MessageContainer>
      )}
    </CompleteProfileContainer>
  );
}

export default CompleteProfile;