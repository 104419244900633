import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import DOMPurify from 'dompurify';
import HotCurrentOffers from '../components/HotCurrentOffers';

const BlogPostContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  background-color: #EAF0F8;
  padding: 50px 30px 0 0;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const LeftSection = styled.div`
  width: 20%;
  padding: 20px;
  font-family: 'Nunito';
  font-size: 1.5vw;
  font-weight: 400;
  color: #2980B9;
  text-align: center;

  .highlight {
    color: #FF5733;
  }
    
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    font-size: 5vw;
    order: 2; /* Display second on mobile */
    }
`;

const MiddleSection = styled.div`
  width: 60%;
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    order: 1; /* Display first on mobile */  
    }
`;

const RightSection = styled.div`
  width: 20%;
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    order: 3; /* Display third on mobile */
  }
`;

const StickyContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 20rem;
`;

const SidebarHeading = styled.h2`
  font-family: 'Poppins';
  font-size: 2vw;
  font-weight: 500;
  color: #2980B9;
  text-align: center;

  .highlight {
    color: #FF5733;
  }

  @media (max-width: 768px) {
    font-size: 8vw  ;
  }
`;

const SubscriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const SubscriptionInput = styled.input`
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Source Sans Pro';
  font-size: 16px;
`;

const SubscriptionButton = styled.button`
  background-color: #FF5733;
  color: #fff;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.6rem;
  font-family: 'Nunito';
  letter-spacing: 0.05rem;
  width: 86%;

  &:hover {
    background-color: #F39C12;
  }
`;

const BlogPostContent = styled.div`
  text-align: center;
`;

const PostImage = styled.img`
  width: 100%;
  max-height: 25rem;
  object-fit: contain;
`;

const PostTitle = styled.h1`
  font-family: 'Poppins';
  font-size: 2vw;
  font-weight: 600;
  color: #2980B9;
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

const PostContent = styled.div`
  font-family: 'Source Sans Pro';
  font-size: 1.4vw;
  font-weight: 400;
  color: #0A1111;
  text-align: left;
  line-height: 1.6;
  margin: 20px 0;

    @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const PostAuthor = styled.div`
  font-family: 'Nunito';
  font-size: 1.3vw;
  font-weight: 700;
  color: #FF5733;
  margin-top: 40px;
  text-align: left;
  width: 100%;
  margin-left: 0;

    @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;
const BlogPost = () => {
  const [post, setPost] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    import(`../posts/${id}.json`)
      .then((data) => setPost(data.default))
      .catch((error) => console.error('Error loading blog post:', error));
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const name = form.name.value;
    const email = form.email.value;

    try {
      await addDoc(collection(firestore, 'subscribers'), {
        name,
        email,
        subscriptionType: 'free',
        timestamp: new Date(),
      });
      alert('Subscribed successfully!');
      form.reset();
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <BlogPostContainer>
      <LeftSection>
      <StickyContainer>      
        <h2 className="current-offers-title">Ofertas <Highlight>Quentes</Highlight></h2>
        <HotCurrentOffers />
      </StickyContainer>
      </LeftSection>
      <MiddleSection>
        <BlogPostContent>
          <PostImage src={post.image} alt="Blog Post" />
          <PostTitle>{post.title}</PostTitle>
          <PostContent dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }} />
          <PostAuthor>
            <i className="far fa-user-circle"></i> {post.author}
          </PostAuthor>
        </BlogPostContent>
      </MiddleSection>
      <RightSection>
        <StickyContainer>
        <SidebarHeading>Subscreve ao<br /> <span className="highlight">Clube de Voos</span><br /></SidebarHeading>
          <SubscriptionForm onSubmit={handleSubmit}>
            <SubscriptionInput 
              type="text" 
              name="name" 
              placeholder="Name" 
              required 
            />
            <SubscriptionInput 
              type="email" 
              name="email" 
              placeholder="Email" 
              required 
            />
            <SubscriptionButton type="submit">Subscreve</SubscriptionButton>
          </SubscriptionForm>
        </StickyContainer>
      </RightSection>
    </BlogPostContainer>
  );
};

export default BlogPost;
