import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TestimonialSection = styled.div`
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15rem;
  padding-right: 15rem;

  @media (max-width: 1024px) { /* Tablet */
    padding-left: 10rem;
    padding-right: 5rem;
  }

  @media (max-width: 768px) { /* Mobile */
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const TestimonialCarousel = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 15rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;

  @media (max-width: 1024px) { /* Tablet */
    height: 12rem;
  }

  @media (max-width: 768px) { /* Mobile */
    height: 10rem;
  }
`;

const Testimonial = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.active {
    position: relative;
    opacity: 1;
  }
`;

const TestimonialText = styled.p`
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 0.2rem;
  text-align: left;
  @media (max-width: 1024px) {
    font-size: 1.1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const TestimonialAuthor = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #F39C12;
  margin-bottom: 0.3rem;
  text-align: left;
  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Testimonials = ({ testimonials }) => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  if (!Array.isArray(testimonials) || testimonials.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <TestimonialSection aria-live="polite">
      <TestimonialCarousel>
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={index}
            className={index === currentTestimonial ? 'active' : ''}
            aria-hidden={index !== currentTestimonial}
          >
            <TestimonialText>"{testimonial.message}"</TestimonialText>
            <TestimonialAuthor>{testimonial.author}</TestimonialAuthor>
          </Testimonial>
        ))}
      </TestimonialCarousel>
    </TestimonialSection>
  );
};

export default Testimonials;












