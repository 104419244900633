// contentBR.js

const contentBR = {
    button: "Estava brincando!",
    passwordupdated: "Senha atualizada com sucesso!",
    cancel: {
      alert1: "Sua conta foi destruída. Puff! Já era!",
      alert2: "Sua assinatura foi alterada para Gratuita.",
    },
    submitalert: "Perfil atualizado com sucesso!",
    subtitle: "Pode atualizar os seguintes campos do seu perfil",
    span: "Atualiza seu aeroporto de origem",
    budgetrange: [
      "Viajante Econômico",
      "Viajante de Médio Alcance",
      "Viajante de Luxo"
    ],
    airlinevalue: "Selecione uma companhia aérea",
  };
  
  export default contentBR;
    