//contentPT
const contentPT = {
  button: "Estava a brincar!",
  passwordupdated: "Senha atualizada com sucesso!",
  cancel: {
    alert1: "A tua conta foi destruida. Puff! Foi-se!",
    alert2: "A tua subscrição passou a Gratuita.",
  },
  submitalert: "Perfil atualizado com sucesso!",
  subtitle: "Podes atualizar os seguintes campos do teu perfil",
  span: "Atualiza o teu aeroporto de origem",
  budgetrange: [
    "Viajante Económico",
    "Viajante de Médio Alcance",
    "Viajante de Luxo"
  ],
  airlinevalue: "Seleciona uma companhia aérea",
};

export default contentPT;
