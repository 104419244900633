// src/components/LandingLayout.js
import React from 'react';
import Footer from './Footer';

const LandingLayout = ({ children }) => {
  return (
    <div>
      {children}
      <Footer />
    </div>
  );
};

export default LandingLayout;

