// src/context/LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('PT'); // Default to PT

  useEffect(() => {
    const fetchRegion = async () => {
      try {
        console.log("Fetching user region...");

        const response = await fetch('/getUserRegion');
        const data = await response.json();

        console.log("Fetched Region:", data.region);
        setLanguage(data.region); // Set the language based on region
      } catch (error) {
        console.error("Error fetching region:", error);
      }
    };

    fetchRegion();
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;

