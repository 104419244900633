import React, { useState } from 'react';
import styled from 'styled-components';

const AccordionSection = styled.div`
  margin-bottom: 10px;
`;

const AccordionTitle = styled.button`
  cursor: pointer;
  padding: 10px;
  background-color: rgba(211, 211, 211, 0.1);
  color: white;
  border-radius: 5px;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: rgba(211, 211, 211, 0.2);
  }

  &:focus {
    outline: 3px solid #2980b9;
  }
`;

const AccordionContent = styled.div`
  padding: 10px;
  background-color: rgba(244, 239, 239, 0.8);
  color: #000000;
  border-radius: 5px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const Accordion = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      {data.map((item, index) => (
        <AccordionSection key={index}>
          <AccordionTitle
            onClick={() => toggleAccordion(index)}
            aria-expanded={openIndex === index}
            aria-controls={`accordion-content-${index}`}
            id={`accordion-title-${index}`}
          >
            {item.title}
            <span>{openIndex === index ? '-' : '+'}</span>
          </AccordionTitle>
          <AccordionContent
            isOpen={openIndex === index}
            id={`accordion-content-${index}`}
            aria-labelledby={`accordion-title-${index}`}
          >
            {item.content}
          </AccordionContent>
        </AccordionSection>
      ))}
    </div>
  );
};

export default Accordion;



